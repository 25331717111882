<template>
  <div>
    <ca-header
      heading="Fahrzeug zum FB hinzufügen"
      :loading="loading"
    >
      <template #heading>
        <h3 class="mb-0">
          Benutzer - Erstellung
        </h3>
      </template>
      <template #alert>
        <transition name="slide-fade">
          <ca-alert
            v-if="!!userCreateErrorMessage"
            variant="danger"
            :message="userCreateErrorMessage"
            @close="userCreateErrorMessage = null"
          />
        </transition>
        <transition name="slide-fade">
          <ca-alert
            v-if="!!error"
            variant="danger"
            :message="error"
            @close="error = null"
          />
        </transition>
        <transition name="slide-fade">
          <ca-alert
            v-if="!!userCreateSuccessMessage"
            variant="success"
            :message="userCreateSuccessMessage"
            @close="userCreateSuccessMessage = null"
          />
        </transition>
        <transition name="slide-fade">
          <ca-alert
            v-if="showFormInvalidHint"
            variant="info"
            message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
            @close="showFormInvalidHint = null"
          />
        </transition>
      </template>
      <template #buttonSpace>
        <button
          :disabled="userSavePending"
          class="btn btn-success text-white"
          @click="save()"
        >
          Speichern
        </button>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div class="row">
        <div class="col-12 col-xl-8 col-xxl-6">
          <PartnerdatenForm
            v-if="!loading"
            :user="user"
            is-create
          />
          <AdminForm
            v-if="!loading"
            :user="user"
          />
          <PaymentForm
            v-if="!loading"
            :user="user"
            is-create
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import formatDate from '../../filters/formatDate.js'
import iban from 'iban'
import AdminForm from '@/components/Forms/Trader/AdminForm.vue'

import PartnerdatenForm from '@/components/Forms/Trader/PartnerdatenForm.vue'
import PaymentForm from '@/components/Forms/Trader/PaymentForm.vue'
import roles from '@/resources/enums/roles.js'

export default {
  name: 'UserEdit',
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    CaHeader,
    PartnerdatenForm,
    PaymentForm,
    AdminForm
  },
  filters: {
    formatDate
  },
  data () {
    return {
      loading: false,
      user: null,
      bankDetailsList: [],
      error: null,
      userSavePending: false,
      userCreateSuccessMessage: null,
      userCreateErrorMessage: null,
      showFormInvalidHint: false,
      tab: 0
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.fetchData()
      }
    }
  },
  methods: {
    async save () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.userSavePending) {
          return
        }
        if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.userCreateErrorMessage = null
        this.showFormInvalidHint = false
        this.userSavePending = true
        if (this.user.payment === 'Lastschrift') {
          await this.saveBankDetails()
        }
        await this.user.save()
        this.userSavePending = false
        this.userCreateSuccessMessage = 'Speichern erfolgreich'
        await this.$router.push({ name: 'UserList' })
      } catch (error) {
        console.error(error)
        this.userCreateErrorMessage = error.message
        this.userSavePending = false
      }
    },
    async saveBankDetails () {
      for (const bank of this.bankDetailsList) {
        if (iban.isValid(bank.IBAN) || !bank.IBAN) {
          const savedBank = await bank.save()
          this.user.bankId.push(savedBank._id)
        } else {
          throw new Error(`${bank.VWZ}: Bitte IBAN überprüfen`)
        }
      }
    },
    async fetchData () {
      try {
        this.loading = true
        this.user = new this.$FeathersVuex.api.User()
        const length = this.bankDetailsList.push(new this.$FeathersVuex.api.BankAccount())
        this.bankDetailsList[length - 1].VWZ = `Konto ${length}`
        this.user.active = true
        this.user.pending = true
        this.$set(this.user, 'features', {})
        this.$set(this.user, 'products', [])
        this.$set(this.user, 'account', { roleId: roles.makler.api })
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
